<template>
    <div>
        <rollover-results />
        <child-layout :title="selectedDistrict.districtName">
            <template v-slot:actions>
                <district-edit-modal ref="districtModal" edit title="Edit District">
                    <template #activator="{ on, attrs }">
                        <v-btn
                            icon
                            :disabled="!hasDistrictEditRole"
                            data-cy="district-edit-modal"
                            v-bind="attrs"
                            v-on="on"
                        >
                            <i class="fal fa-pencil" />
                        </v-btn>
                    </template>
                </district-edit-modal>
            </template>
            <v-row v-if="displayLoading">
                <v-col sm="3">
                    <v-skeleton-loader type="card-avatar" />
                </v-col>
                <v-col>
                    <v-skeleton-loader type="table-tbody" />
                </v-col>
            </v-row>
            <v-row v-else class="district">
                <v-col class="district__logo-col" justify="center" align="center">
                    <img v-if="client.logo" src="client.logo" />
                    <picture v-else>
                        <source type="image/svg+xml" srcset="@/assets/IE_logo_text.svg" />
                        <img src="@/assets/IE_logo_mark.png" />
                    </picture>

                    <v-row class="district__statuses" justify="center" align="center">
                        <div class="district__status px-2">
                            <v-label>Status</v-label>
                            <v-chip
                                label
                                text-color="white"
                                :color="status"
                                class="district__status-chip"
                                >{{ client.status || 'Disabled' }}</v-chip
                            >
                        </div>
                        <div class="district__status px-2">
                            <v-label>Client Health</v-label>
                            <v-chip
                                color="grey"
                                text-color="white"
                                class="district__status-chip"
                                label
                                >Unavailable</v-chip
                            >
                        </div>
                    </v-row>
                </v-col>
                <v-col lg="4">
                    <v-row class="child-layout__label">Legal Name</v-row>
                    <v-row class="child-layout__info" data-cy="legal-name">{{ client.name }}</v-row>
                    <v-row class="child-layout__label">Display Name</v-row>
                    <v-row class="child-layout__info" data-cy="display-name">{{
                        client.displayName
                    }}</v-row>
                    <v-row class="child-layout__label">Client ID</v-row>
                    <v-row class="child-layout__info" data-cy="client-id">
                        <router-link
                            :to="{
                                name: 'client-view',
                                params: { id: selectedDistrict.illuminateClientId },
                            }"
                        >
                            {{ selectedDistrict.illuminateClientId }}
                        </router-link>
                    </v-row>
                    <v-row class="child-layout__label">SIS District ID</v-row>
                    <v-row class="child-layout__info" data-cy="sis-district-id">{{
                        selectedDistrict.id
                    }}</v-row>
                    <v-row class="child-layout__label">NCES District ID</v-row>
                    <v-row class="child-layout__info" data-cy="nces-district-id">{{
                        client.ncesId
                    }}</v-row>
                    <v-row class="child-layout__label">LEA ID</v-row>
                    <v-row class="child-layout__info" data-cy="lea-id">{{
                        selectedDistrict.lea
                    }}</v-row>
                    <v-row class="child-layout__label">SF Account Number</v-row>
                    <v-row class="child-layout__info" data-cy="sf-account-number">{{
                        client.sfAccountNumber
                    }}</v-row>
                    <v-row class="child-layout__label">CRM Account Number</v-row>
                    <v-row class="child-layout__info" data-cy="crm-account-number">{{
                        client.crmAccountNumber
                    }}</v-row>
                </v-col>
                <v-col>
                    <v-row class="child-layout__label">District Address</v-row>
                    <v-row class="child-layout__info" data-cy="district-address">{{
                        selectedDistrict.address
                    }}</v-row>
                    <v-row class="child-layout__label">Phone</v-row>
                    <v-row class="child-layout__info" data-cy="phone">{{
                        selectedDistrict.phoneNumber
                    }}</v-row>
                    <v-row class="child-layout__label">State</v-row>
                    <v-row class="child-layout__info" data-cy="state">{{ client.state }}</v-row>
                    <v-row class="child-layout__label">County</v-row>
                    <v-row class="child-layout__info" data-cy="county">{{ client.county }}</v-row>
                    <v-row class="child-layout__label">Created Date</v-row>
                    <v-row class="child-layout__info" data-cy="created-date">{{ createdAt }}</v-row>
                    <v-row class="child-layout__label">Last Updated Date</v-row>
                    <v-row class="child-layout__info" data-cy="updated-date">{{ updatedAt }}</v-row>
                    <v-row class="child-layout__label">Data Year</v-row>
                    <v-row class="child-layout__info">{{ dataYear }}</v-row>
                </v-col>
                <v-col lg="2">
                    <v-row class="child-layout__label">District Integration Contact</v-row>
                    <v-row class="child-layout__info" data-cy="contact-first-name"
                        >{{ selectedDistrict.contactFirstName }}
                        {{ selectedDistrict.contactLastName }}</v-row
                    >
                    <v-row class="child-layout__label">Email</v-row>
                    <v-row class="child-layout__info" data-cy="contact-email-address">{{
                        selectedDistrict.contactEmailAddress
                    }}</v-row>
                    <v-row class="child-layout__label">Phone</v-row>
                    <v-row class="child-layout__info" data-cy="contact-phone-number">{{
                        selectedDistrict.contactPhoneNumber
                    }}</v-row>
                    <v-row class="child-layout__label">Block Imports</v-row>
                    <v-row class="child-layout__info" data-cy="block-imports">
                        <v-chip
                            :color="blockImportColor"
                            class="district__status-chip px-8"
                            label
                            text-color="white"
                        >
                            {{ selectedDistrict.blockImports ? 'Yes' : 'No' }}
                        </v-chip>
                    </v-row>
                </v-col>
            </v-row>
        </child-layout>
        <child-layout title="Products" class="products">
            <template v-slot:actions> </template>
            <v-container fluid>
                <v-row>
                    <div
                        v-for="(product, index) in clientProducts"
                        :key="index"
                        class="product-list"
                    >
                        <product-logo :product="product" />
                    </div>
                </v-row>
            </v-container>
        </child-layout>
    </div>
</template>

<script>
import ChildLayout from '@/components/layout/ChildLayout'
import { mapActions, mapGetters } from 'vuex'
import { DateTime } from 'luxon'
import ProductLogo from '@/components/ProductLogo'
import DistrictEditModal from '@/views/DistrictManagement/DistrictEditModal'
import RolloverResults from '@/views/DistrictManagement/RolloverResults'
import { formatYear } from '@/helpers/shared.js'
import { ROLE_SETUP_DISTRICT_EDIT } from '@/helpers/security/roles'

export default {
    components: {
        ProductLogo,
        ChildLayout,
        DistrictEditModal,
        RolloverResults,
    },
    data: () => ({
        form: {
            ready: false,
            error: [],
            data: {
                client: null,
                selectedDistrict: null,
                products: [],
            },
        },
    }),
    computed: {
        ...mapGetters({
            getClient: 'client/getItemById',
            getProducts: 'client/getClientProducts',
            selectedDistrict: 'getSelectedDistrict',
            hasRole: 'auth/hasRole',
            clientFetching: 'client/getFetching',
        }),
        dataYear() {
            const year = parseInt(this.selectedDistrict.sisYear)
            // This will preserve the value in the list that is currently entered.
            // this is helpful for ensuring that the value that appears is the value in the db.
            return year
                ? formatYear(parseInt(this.selectedDistrict.sisYear))
                : this.selectedDistrict.sisYear
        },
        hasDistrictEditRole() {
            return this.hasRole(ROLE_SETUP_DISTRICT_EDIT)
        },
        status() {
            return this.client.status === 'enabled' ? 'green' : 'red'
        },
        blockImportColor() {
            return this.selectedDistrict.blockImports ? 'red' : 'green'
        },
        readOnly() {
            return true
        },
        createdAt() {
            return this.client.createdAt
                ? DateTime.fromISO(this.client.createdAt).toLocaleString()
                : ''
        },
        updatedAt() {
            return this.client.updatedAt
                ? DateTime.fromISO(this.client.updatedAt).toLocaleString()
                : ''
        },
        client() {
            return this.getClient(this.selectedDistrict.illuminateClientId) || {}
        },
        clientProducts() {
            return this.getProducts(this.selectedDistrict.illuminateClientId)
        },
        displayLoading() {
            return this.clientFetching && !this.$refs.districtModal?.opened
        },
    },
    async mounted() {
        const { illuminateClientId } = this.selectedDistrict
        await Promise.all([
            this.fetchClient(illuminateClientId),
            this.fetchProducts(illuminateClientId),
        ])
    },
    methods: {
        ...mapActions({
            fetchClient: 'client/get',
            fetchProducts: 'client/getProducts',
        }),
    },
}
</script>

<style lang="scss" scoped>
.district {
    margin: 3px 50px;

    // all the columns are a little loosey goosey. design comp is basically set up so that lines don't wrap.
    // I've tried to more or less preserve that on a  1440px width canvas.
    &__logo-col {
        margin: 30px auto auto auto;
    }

    &__statuses {
        margin-top: 40px;
        padding-top: 0;
        label {
            font-size: 12px;
        }
    }

    &__status-chip {
        color: white;
        display: block;
        font-size: 12px;
        height: 24px;
        margin: 5px 0;
        &:before {
            border-radius: 4px;
        }
    }

    &__status {
        color: rgba(5, 15, 45, 0.7);
        padding-left: 5px;
        padding-right: 5px;
        line-height: 0;
        text-transform: capitalize;
    }

    &__health {
        color: white;
        margin-top: 5px;
    }
}

.product-list {
    width: 103px;
    /* align to the bottom allow text to spill over up top for products that have line breaks. */
    margin: auto 16px 40px;
}

.child-layout__info {
    margin-top: 0px;
}
</style>
