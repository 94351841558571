<template>
    <alert-message v-if="active" :type="type" data-cy="rollover-result">
        <strong>{{ result }}</strong> {{ details }}
    </alert-message>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import AlertMessage from '@/components/alert/AlertMessage'
export default {
    components: {
        AlertMessage,
    },
    computed: {
        ...mapGetters({
            type: 'rollover/getStatusType',
            active: 'rollover/getActive',
            results: 'rollover/getResults',
            district: 'getSelectedDistrict',
        }),
        details() {
            if (!this.active) {
                return ''
            }

            if (this.type === 'error') {
                return 'Please try again.'
            }

            if (this.type === 'info') {
                return ''
            }

            return `${this.results?.exports?.rowsInserted ?? 0} Exports, ${
                this.results?.staff?.rowsInserted ?? 0
            } Users and ${this.results?.templates?.rowsInserted ?? 0} Templates Updated`
        },
        result() {
            switch (this.type) {
                case 'error':
                    return 'Rollover Failed.'
                case 'info':
                    return 'Rollover Processing.'
                case 'success':
                    return 'Rollover Successful.'
            }
            return ''
        },
    },
    watch: {
        district(newId, oldId) {
            if (this.active) {
                this.reset()
            }
        },
    },
    methods: {
        ...mapActions({
            reset: 'rollover/reset',
        }),
    },
}
</script>

<style></style>
